import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";

const SectionF = ({ handleInputChange, KYCDetails, isinvalid }) => {
  return (
    <Form.Group controlId="formStep6" className="mb-5 mb-md-0">
      <h1 id="titleSF" className="pg-title mb-4">
        KYC Form
      </h1>
      <Form.Group className="mb-3">
        <Form.Label className={isinvalid && !KYCDetails.q1 ? "is-invalid" : ""}>
          1. Are you in adherence with the provisions outlined in the Malaysia
          Anti-Money Laundering and Anti-Terrorism Financing and Proceeds of
          Unlawful Activities Act (AMLA) 2001?*
        </Form.Label>
        <Form.Control.Feedback type="invalid">
          Please select either one option.
        </Form.Control.Feedback>
        <Col sm={10}>
          <Form.Check
            type="radio"
            label="Yes"
            name="q1"
            id="q11"
            value={true}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q1 === true}
            isInvalid={isinvalid && !KYCDetails.q1}
          />
          <Form.Check
            type="radio"
            label="No"
            name="q1"
            id="q12"
            value={false}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q1 === false}
            isInvalid={isinvalid && !KYCDetails.q1}
          />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className={isinvalid && !KYCDetails.q2 ? "is-invalid" : ""}>
          2. Do you engage with individuals or nations listed under sanctions?*
        </Form.Label>
        <Form.Control.Feedback type="invalid">
          Please select either one option.
        </Form.Control.Feedback>
        <Col sm={10}>
          <Form.Check
            type="radio"
            label="Yes"
            name="q2"
            id="q21"
            value={true}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q2 === true}
            isInvalid={isinvalid && !KYCDetails.q2}
          />
          <Form.Check
            type="radio"
            label="No"
            name="q2"
            id="q22"
            value={false}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q2 === false}
            isInvalid={isinvalid && !KYCDetails.q2}
          />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className={isinvalid && !KYCDetails.q3 ? "is-invalid" : ""}>
          3. In the past three years, have you filled reports to Malaysian
          authorities regarding any individual or entity in connection with a
          suspicious transaction?*
        </Form.Label>
        <Form.Control.Feedback type="invalid">
          Please select either one option.
        </Form.Control.Feedback>
        <Col sm={10}>
          <Form.Check
            type="radio"
            label="Yes"
            name="q3"
            id="q31"
            value={true}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q3 === true}
            isInvalid={isinvalid && !KYCDetails.q3}
          />
          <Form.Check
            type="radio"
            label="No"
            name="q3"
            id="q32"
            value={false}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q3 === false}
            isInvalid={isinvalid && !KYCDetails.q3}
          />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className={isinvalid && !KYCDetails.q4 ? "is-invalid" : ""}>
          4. Do you preserve and store pertinent documents, such as transaction
          records or customer documents, generated or acquired during business
          operations?*
        </Form.Label>
        <Form.Control.Feedback type="invalid">
          Please select either one option.
        </Form.Control.Feedback>
        <Col sm={10}>
          <Form.Check
            type="radio"
            label="Yes"
            name="q4"
            id="q41"
            value={true}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q4 === true}
            isInvalid={isinvalid && !KYCDetails.q4}
          />
          <Form.Check
            type="radio"
            label="No"
            name="q4"
            id="q42"
            value={false}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q4 === false}
            isInvalid={isinvalid && !KYCDetails.q4}
          />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className={isinvalid && !KYCDetails.q5 ? "is-invalid" : ""}>
          5. Do you make an assessment of or evaluate who you are having
          business with, including third party contracts/ vendors/ suppliers?*
        </Form.Label>
        <Form.Control.Feedback type="invalid">
          Please select either one option.
        </Form.Control.Feedback>
        <Col sm={10}>
          <Form.Check
            type="radio"
            label="Yes"
            name="q5"
            id="q51"
            value={true}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q5 === true}
            isInvalid={isinvalid && !KYCDetails.q5}
          />
          <Form.Check
            type="radio"
            label="No"
            name="q5"
            id="q52"
            value={false}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q5 === false}
            isInvalid={isinvalid && !KYCDetails.q5}
          />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className={isinvalid && !KYCDetails.q6 ? "is-invalid" : ""}>
          6. In the past five years, have you faced regulatory or criminal
          enforcement actions due to breaches of AMlA 2001 or related
          regulations?*
        </Form.Label>
        <p className="mb-3">
          (If Yes, please state what actions were taken and send to our E-mail)
        </p>
        <Form.Control.Feedback type="invalid">
          Please select either one option.
        </Form.Control.Feedback>
        <Col sm={10}>
          <Form.Check
            type="radio"
            label="Yes"
            name="q6"
            id="q61"
            value={true}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q6 === true}
            isInvalid={isinvalid && !KYCDetails.q6}
          />
          <Form.Check
            type="radio"
            label="No"
            name="q6"
            id="q62"
            value={false}
            onChange={handleInputChange}
            defaultChecked={KYCDetails.q6 === false}
            isInvalid={isinvalid && !KYCDetails.q6}
          />
        </Col>
      </Form.Group>
    </Form.Group>
  );
};

export default SectionF;
