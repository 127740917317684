import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { Plus } from "react-feather";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  TabContent,
  Table,
  Form,
  InputGroup,
  ButtonGroup,
  DropdownDivider,
  Dropdown,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
//Import Modal
import AddFormModal from "./AddFormModal";
import LinkModalForm from "./LinkModal";
import EditFormModal from "./EditFormModal";
import DeleteFormModal from "./DeleteFormModal";
import LoanApplicationModal from "./UploadModalFolder/LoanApplicationModal";
import ApproveModal from "./ApproveModal";
import RejectModal from "./RejectModal";
import OfferLetterSignedModal from "./UploadModalFolder/OfferLetterSignedModal";
import BankReceiptModal from "./UploadModalFolder/BankReceiptModal";
import ScheduleModal from "./ScheduleModal";
import ScheduleUploadModal from "./UploadModalFolder/ScheduleUploadModal";
import DisbursementModal from "./UploadModalFolder/DisbursementModal";
import MemoModal from "./UploadModalFolder/MemoModal";
import FirstReminderModal from "./ReminderModal/First_ReminderLetterModal";
import SecondReminderModal from "./ReminderModal/Second_ReminderLetterModal";
import WarningLetterModal from "./ReminderModal/WarningLetterModal";
import { ToastContainer, toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const isAuthenticated = async () => {
  // Check if the access token is present
  const accessToken = Cookies.get("accessToken");
  const refreshToken = Cookies.get("refreshToken");
  if (!accessToken) {
    return false;
  }
  try {
    // Decode the token (optional, but you might want to access its payload)
    const decodedToken = jwtDecode(accessToken);

    // Check if the token is expired
    if (decodedToken.exp < Date.now() / 1000) {
      // Token is expired, remove it from cookies and return false
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return false;
    } else {
      // Token is valid
      return true;
    }
  } catch (error) {
    // An error occurred while decoding the token (could be invalid)
    // Remove the token from cookies and return false
    Cookies.remove("accessToken");
    return false;
  }
};

const LoanApplicationView = (props) => {
  //access token variable
  const [Token, setToken] = useState(null);
  const history = useHistory();
  // Modal show variable
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showLinkModalForm, setShowLinkModalForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [showApproveForm, setShowApproveForm] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [showBankReceiptForm, setShowBankReceiptForm] = useState(false);
  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const [showOfferLetter, setShowOfferLetter] = useState(false);
  const [showUploadScheduleForm, setShowUploadScheduleForm] = useState(false);
  const [showDisbursementForm, setShowDisbursementForm] = useState(false);
  const [showMemoForm, setShowMemoForm] = useState(false);
  const [showFirstReminderModal, setFirstReminderForm] = useState(false);
  const [showSecondReminderModal, setSecondReminderForm] = useState(false);
  const [showWarningLetter, setWarningLetter] = useState(false);

  const [showDeleted, setShowDeleted] = useState(false);
  // Link variable
  const [linkGeneration, setLinkGeneration] = useState("");
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // Fetch data use
  const [lists, setList] = useState("");
  // ID get
  const [loanApplicationId, setLoanApplicationId] = useState(null);
  // variable for Schedule Modal
  const [secured, setIsSecured] = useState("");
  const [loanApplicationNumber, setLoanApplicationNumber] = useState("");
  const [clientName, setClientName] = useState("");

  //Search variable
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([...lists]);
  // Add a new state variable for selected category
  const [selectedCategory, setSelectedCategory] = useState("clientName");

  // Status array
  const [statusArray, setStatusArray] = useState([
    "Pending - Loan Application Details",
    "Pending - Personal Details",
    "Pending - Employment Details",
    "Pending - Important Documents",
    "Pending - Disbursement Details",
    "Pending - KYC Details",
    "Pending - Download Loan Application",
    "Pending - Sign Loan Application",
    "Pending - Approve / Reject",
    "Rejected",
    "Approved",
    "Pending - Download Loan Offer",
    "Pending - Sign Loan Offer",
    "Pending - Bank Receipt",
    "Pending - Schedule Details",
    "Pending - Download Schedule",
    "Pending - Sign Schedule",
    "Pending - Disbursement",
    "Pending - Download Memo",
    "Pending - Download Disbursement",
    "Pending - Sign Memo",
    "Pending - Sign Disbursement",
    "Disbursed",
  ]);

  // Loan Application status to number
  const loanApplicationDetails = 0;
  const personalDetails = 1;
  const employmentDetails = 2;
  const importantDocument = 3;
  const disbursementDetails = 4;
  const KYCDatails = 5;
  const downloadLoanApplication = 6;
  const signLoanApplication = 7;
  const approve_rejectButton = 8;
  const rejected = 9;
  const approved = 10;
  const downloadLoanOffer = 11;
  const signLoanOffer = 12;
  const uploadedBankReceipt = 13;
  const scheduleDetails = 14;
  const downloadSchedule = 15;
  const signSchedule = 16;
  const disbursement = 17;
  const downloadMemo = 18;
  const downloadDisbursement = 19;
  const signMemo = 20;
  const signDisbursement = 21;
  const disbursed = 22;

  //pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //Update the render to use filteredLists
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  // Create new Application
  const handleCreateClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    setList("");
    setSearchTerm("");
    const checkAuthentication = async () => {
      if (!(await isAuthenticated())) {
        history.push("/auth/login-classic");
      }
    };
    checkAuthentication();
    setCurrentPage(1);
    //access token variable
    const accessToken = Cookies.get("accessToken");
    setToken(accessToken);
    fetchData(accessToken);
  }, [showDeleted]);

  useEffect(() => {
    if (searchTerm && Array.isArray(lists)) {
      const filterItems = lists.filter(
        (item) =>
          item.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.ICNumber &&
            item.ICNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.passportNumber &&
            item.passportNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.companyRegistrationNo &&
            item.companyRegistrationNo
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.companyName &&
            item.companyName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          item.loanApplicationNo
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
      setFilteredLists(filterItems);
    } else {
      setFilteredLists([...lists]);
    }
  }, [lists, searchTerm]);

  //fetch all application data
  const fetchData = async (Token) => {
    let loanApplication;
    showDeleted ? (loanApplication = "deleted") : (loanApplication = "active");

    await fetch(`${backendUri}/api/admin/get-applications/${loanApplication}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.loanApplications && data.loanApplications.length > 0) {
          setList(data.loanApplications);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.error("Login Failed due to :" + err.message);
      });
  };

  //Delete
  const handleDelete = (id) => {
    setLoanApplicationId(id);
    setShowDeleteForm(true);
  };
  const closeHandleDelete = () => {
    setShowDeleteForm(false);
  };

  //Get Link for a loan application
  const handleCloseLinkModalForm = () => {
    setShowLinkModalForm(false);
  };

  const handleLink = (id) => {
    fetch(`${backendUri}/api/admin/get-application-link/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setLinkGeneration(data.loanApplicationLink);
      })
      .catch((err) => {
        console.error("Error deleting item", err.message);
      });

    setShowLinkModalForm(true);
  };

  //Edit
  const handleEdit = (id) => {
    setLoanApplicationId(id);
    setShowEditForm(true);
  };
  const handleCloseEditForm = () => {
    setShowEditForm(false);
  };

  // Download
  // id = clientId,name = clientName, loanApplicationNo = loanApplication Number,downloadFileName = download file name
  // fileType & category for API routes
  const handleDownload = (
    id,
    name,
    loanApplicationNo,
    fileType,
    category,
    downloadFileName
  ) => {
    fetch(`${backendUri}/api/admin/download/${fileType}/${category}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then(async (res) => {
        if (!res.ok) {
          toast.error(
            `${
              fileType.charAt(0).toUpperCase() + fileType.slice(1)
            } ${category} not found or not yet uploaded`,
            { containerId: "success-container" }
          );
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        // if (category === "clientBasic") {
        //   const blob = await res.blob();

        // Extract the filename from the Content-Disposition header
        // const contentDisposition = res.headers.get(
        //   "Content-Disposition"
        // );
        // let filename = "loan_application_files.zip"; // default filename

        // if (contentDisposition && contentDisposition.includes("filename=")) {
        //   const matches = /filename="([^"]+)"/.exec(contentDisposition);
        //   if (matches && matches[1]) {
        //     filename = matches[1];
        //   }
        // }

        //   const url = window.URL.createObjectURL(blob);
        //   const a = document.createElement("a");
        //   a.href = url;
        //   a.download = `${loanApplicationNo}.zip`;
        //   document.body.appendChild(a);
        //   a.click();
        //   a.remove();
        //   window.URL.revokeObjectURL(url);
        //   fetchData(Token);
        // } else {
        const contentDisposition = res.headers.get("Content-Disposition");
        const filename = contentDisposition
          .split("filename=")[1]
          .split(";")[0]
          .replace(/"/g, "");
        const blob = await res.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;

        // const fileName = `${loanApplicationNo}_${name}_${downloadFileName}`;

        link.setAttribute("download", `${filename}`);

        document.body.appendChild(link);
        link.click();
        // window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        // }

        fetchData(Token);
      })
      // .then((data) => {
      //   if (data) {
      //     toast.success(data.message, { containerId: "success-container" });
      //     props.fetchData(props.accessToken);
      //     props.handleCloseForm();
      //   } else {
      //     toast.error(data.error, { containerId: "success-container" });
      //   }
      // })
      .catch((error) => {
        console.error("API Fetch Fail", error);
      });
  };

  // Upload
  const handleUploadModal = (id, isSecured) => {
    setLoanApplicationId(id);
    setIsSecured(isSecured);
    setShowUploadForm(true);
  };
  const handleCloseUploadForm = () => {
    setShowUploadForm(false);
  };

  // Approve
  const handleApproveModal = (id, isSecured) => {
    setLoanApplicationId(id);
    setIsSecured(isSecured);
    setShowApproveForm(true);
  };
  const handleCloseApproveForm = () => {
    setShowApproveForm(false);
  };

  // Reject
  const handleRejectModal = (id) => {
    setLoanApplicationId(id);
    setShowRejectForm(true);
  };
  const handleCloseRejectForm = () => {
    setShowRejectForm(false);
  };

  // Upload Offer Letter Modal
  const handleOfferLetterModal = (id) => {
    setLoanApplicationId(id);
    setShowOfferLetter(true);
  };
  const handleCloseOfferForm = () => {
    setShowOfferLetter(false);
  };

  // Bank Receipt Modal
  const handleBankReceiptModal = (id) => {
    setLoanApplicationId(id);
    setShowBankReceiptForm(true);
  };
  const handleCloseBankReceiptForm = () => {
    setShowBankReceiptForm(false);
  };
  // Schedule
  const handleScheduleModal = (id, isSecured, number, name) => {
    setLoanApplicationId(id);
    setIsSecured(isSecured);
    setLoanApplicationNumber(number);
    setClientName(name);
    setShowScheduleForm(true);
  };
  const handleCloseScheduleForm = () => {
    setShowScheduleForm(false);
  };

  // Schedule Upload Modal
  const handleScheduleUploadModal = (id) => {
    setLoanApplicationId(id);
    setShowUploadScheduleForm(true);
  };
  const handleCloseScheduleUploadForm = () => {
    setShowUploadScheduleForm(false);
  };
  // Disbursement Upload Modal
  const handleDisbursmentModal = (id) => {
    setLoanApplicationId(id);
    setShowDisbursementForm(true);
  };
  const handleCloseDisbursementModal = (id) => {
    setShowDisbursementForm(false);
  };

  // Memo Upload Modal
  const handleMemoUploadModal = (id) => {
    setLoanApplicationId(id);
    setShowMemoForm(true);
  };
  const handleCloseMemoModal = (id) => {
    setShowMemoForm(false);
  };

  // First Reminder Modal
  const handleFirstReminderModal = (id, name, number) => {
    setLoanApplicationId(id);
    setClientName(name);
    setLoanApplicationNumber(number);
    setFirstReminderForm(true);
  };
  const handleCloseFirstReminderModal = () => {
    setFirstReminderForm(false);
  };

  // Second Reminder Modal
  const handleSecondReminderModal = (id, name, number) => {
    setLoanApplicationId(id);
    setClientName(name);
    setLoanApplicationNumber(number);
    setSecondReminderForm(true);
  };
  const handleCloseSecondReminderModal = () => {
    setSecondReminderForm(false);
  };

  // Warning Letter Modal

  const handleWarningLetterModal = (id, name, number) => {
    setLoanApplicationId(id);
    setClientName(name);
    setLoanApplicationNumber(number);
    setWarningLetter(true);
  };
  const handleCloseWarningLetterModal = () => {
    setWarningLetter(false);
  };

  // Disburse Button
  const handleDisburse = (id) => {
    fetch(`${backendUri}/api/admin/disburse/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          console.error(res.error);
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });

          fetchData(Token);
        }
      })
      .catch((err) => {
        console.error("Fetch API fail");
      });
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const tooltip = (
    <Tooltip>
      Show {showDeleted ? "Active" : "Deleted"} Loan Application
    </Tooltip>
  );

  return (
    <>
      <Container>
        <TabContent>
          <Card
            className="card-border mb-0 h-100 "
            style={{ minWidth: "1000px" }}
          >
            <Card.Header className="card-header-action">
              <div style={{ display: "flex" }}>
                {" "}
                <h5>LOAN APPLICATION</h5>
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <Form.Check
                    style={{ marginLeft: "20px" }}
                    type="switch"
                    id="showDeletedLoanApplication"
                    checked={showDeleted}
                    onChange={() => setShowDeleted(!showDeleted)}
                    disabled={!lists}
                  />
                </OverlayTrigger>
                <Col style={{ marginLeft: "10px" }}>
                  <span
                    className={
                      showDeleted
                        ? "badge-soft-danger badge bg-danger-light-5"
                        : "badge-soft-primary badge bg-primary-light-5"
                    }
                  >
                    {showDeleted ? "Deleted" : "Active"}
                  </span>
                </Col>
              </div>

              <div className="card-action-wrap">
                {/* <Button
                  variant="danger btn-animated"
                  size="sm"
                  onClick={showDeletedUndeletedLoanApplication}
                >
                  Show {showDeleted ? "active" : "deleted"} loan application
                </Button> */}
                <Button
                  variant="primary btn-animated"
                  size="sm"
                  className="ms-3"
                  onClick={handleCreateClick}
                >
                  <span>
                    <span className="icon">
                      <span className="feather-icon">
                        <Plus />
                      </span>
                    </span>
                    <span className="btn-text " size="lg">
                      Add new
                    </span>
                  </span>
                </Button>
              </div>
            </Card.Header>
            {lists ? (
              <Card.Body>
                <InputGroup className="mb-4">
                  <Form.Control
                    className="table-search"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>
                {Array.isArray(currentItems) && currentItems.length > 0 ? (
                  <div className="invoice-list-view">
                    <Table hover className="mb-0">
                      <thead>
                        <tr>
                          <th>
                            <span className="d-flex">
                              <span className="flex-grow-1">Client Name</span>
                            </span>
                          </th>
                          <th>
                            <span className="d-flex">
                              <span className="flex-grow-1">
                                IC Number/Passport Number
                              </span>
                            </span>
                          </th>
                          <th>
                            <span className="d-flex">
                              <span className="flex-grow-1">Company</span>
                            </span>
                          </th>

                          <th>
                            <span className="d-flex">
                              <span className="flex-grow-1">
                                Loan Application Number
                              </span>
                            </span>
                          </th>
                          <th>
                            <span className="d-flex">
                              <span className="flex-grow-1">Status</span>
                            </span>
                          </th>
                          <th>
                            <span className="d-flex">
                              <span className="flex-grow-1">Action</span>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item) => (
                          <tr key={item.loanApplicationNo}>
                            <td>{item.clientName}</td>
                            <td>{item.indentityNumber}</td>
                            <td>
                              {item.companyName} <br />
                              {item.companyRegistrationNo}
                            </td>
                            {/* <td>{item.companyRegistrationNo}</td> */}
                            <td>{item.loanApplicationNo}</td>

                            <td>
                              {showDeleted ? (
                                <>
                                  {" "}
                                  <Col>
                                    <span className="badge-soft-danger badge  bg-danger-light-5">
                                      Deleted
                                    </span>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {item.status === rejected ? (
                                    <Col md={8}>
                                      <span className="my-1 badge-soft-danger badge  bg-danger-light-5">
                                        Rejected
                                      </span>
                                    </Col>
                                  ) : item.status === disbursed ? (
                                    <>
                                      <Col md={8}>
                                        <span className="my-1 badge-soft-success badge  bg-success-light-5">
                                          Disbursed
                                        </span>
                                      </Col>
                                    </>
                                  ) : (
                                    <Col md={8}>
                                      <span className="my-1 badge-soft-light badge bg-secondary-light-5">
                                        {statusArray[item.status]}
                                      </span>
                                    </Col>
                                  )}
                                </>
                              )}
                            </td>

                            <td>
                              {showDeleted ? (
                                <>
                                  {" "}
                                  <Button
                                    variant="soft-primary"
                                    id="rejectBtn"
                                    size="sm"
                                    className="btn-rounded float-right m-2"
                                    onClick={() =>
                                      handleDelete(item.loanApplicationId)
                                    }
                                  >
                                    Restore
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <Dropdown
                                    as={ButtonGroup}
                                    className="float-end"
                                  >
                                    <Dropdown.Toggle
                                      tile="Dropdown"
                                      variant="flush-dark"
                                      id="dropdown-split-basic"
                                    />

                                    <Dropdown.Menu>
                                      {item.status < rejected ? (
                                        <>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleEdit(item.loanApplicationId)
                                            }
                                            id="edit"
                                          >
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleDelete(
                                                item.loanApplicationId
                                              )
                                            }
                                            id="delete"
                                          >
                                            Delete
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleLink(item.loanApplicationId)
                                            }
                                            id="formLink"
                                          >
                                            Form Link
                                          </Dropdown.Item>
                                        </>
                                      ) : (
                                        <>
                                          <Dropdown.Item
                                            disabled
                                            id="disabledEdit"
                                          >
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            disabled
                                            id="disabledDelete"
                                          >
                                            Delete
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            disabled
                                            id="disabledFormLink"
                                          >
                                            Form Link
                                          </Dropdown.Item>
                                        </>
                                      )}

                                      {item.status >=
                                        downloadLoanApplication && (
                                        <DropdownDivider />
                                      )}

                                      {/* ------------------------------------------------- Upload Dropdown ---------------------------------------------------------*/}
                                      {item.status >= signLoanApplication && (
                                        <>
                                          <Dropdown as={ButtonGroup} drop="end">
                                            <Dropdown.Toggle
                                              as={Link}
                                              id="dropdown-upload"
                                              size="sm"
                                              to="#"
                                              className="dropdown-item"
                                            >
                                              Upload
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Header as="h6">
                                                Upload :
                                              </Dropdown.Header>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleUploadModal(
                                                    item.loanApplicationId
                                                  )
                                                }
                                                size="sm"
                                                id="upload"
                                              >
                                                Loan Application Form
                                              </Dropdown.Item>

                                              {item.status >= signLoanOffer && (
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleOfferLetterModal(
                                                      item.loanApplicationId
                                                    )
                                                  }
                                                  id="uploadOfferLetter"
                                                >
                                                  OfferLetter
                                                </Dropdown.Item>
                                              )}
                                              {item.status >=
                                                uploadedBankReceipt && (
                                                <Dropdown.Item
                                                  id="bankReceiptUpload"
                                                  onClick={() =>
                                                    handleBankReceiptModal(
                                                      item.loanApplicationId
                                                    )
                                                  }
                                                >
                                                  Bank Receipt
                                                </Dropdown.Item>
                                              )}
                                              {item.status >= signSchedule && (
                                                <Dropdown.Item
                                                  id="scheduleUpload"
                                                  onClick={() =>
                                                    handleScheduleUploadModal(
                                                      item.loanApplicationId,
                                                      item.isSecured
                                                    )
                                                  }
                                                >
                                                  Schedule
                                                </Dropdown.Item>
                                              )}
                                              {item.status >= signMemo && (
                                                <Dropdown.Item
                                                  id="memoUpload"
                                                  onClick={() => {
                                                    handleMemoUploadModal(
                                                      item.loanApplicationId
                                                    );
                                                  }}
                                                >
                                                  Memo
                                                </Dropdown.Item>
                                              )}
                                              {item.status >=
                                                signDisbursement && (
                                                <Dropdown.Item
                                                  id="disbursementUpload"
                                                  onClick={() =>
                                                    handleDisbursmentModal(
                                                      item.loanApplicationId
                                                    )
                                                  }
                                                >
                                                  Disbursement
                                                </Dropdown.Item>
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </>
                                      )}
                                      {/* ------------------------------------------------- Download Dropdown ---------------------------------------------------------*/}
                                      {item.status >=
                                        downloadLoanApplication && (
                                        <Dropdown as={ButtonGroup} drop="end">
                                          <Dropdown.Toggle
                                            as={Link}
                                            id="dropdown-download"
                                            size="sm"
                                            to="#"
                                            className="dropdown-item"
                                          >
                                            Download
                                          </Dropdown.Toggle>
                                          {/* <Dropdown.Menu>
                                            <Dropdown.Header as="h6">
                                              Download (customer file) :
                                            </Dropdown.Header>
                                          </Dropdown.Menu> */}
                                          <Dropdown.Menu>
                                            {/* ------------------------------------------------- Customer files ---------------------------------------------------------*/}

                                            <Dropdown.Header as="h6">
                                              Download (customer file) :
                                            </Dropdown.Header>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleDownload(
                                                  item.loanApplicationId,
                                                  item.clientName,
                                                  item.loanApplicationNo,
                                                  "basic",
                                                  "clientBasic",
                                                  "LoanApplicationForm"
                                                )
                                              }
                                              size="sm"
                                              id="download"
                                            >
                                              Customer File
                                            </Dropdown.Item>

                                            <DropdownDivider />
                                            {/* ------------------------------------------------- Unsign ---------------------------------------------------------*/}
                                            <Dropdown.Header as="h6">
                                              Download (unsign) :
                                            </Dropdown.Header>

                                            <Dropdown.Item
                                              onClick={() =>
                                                handleDownload(
                                                  item.loanApplicationId,
                                                  item.clientName,
                                                  item.loanApplicationNo,
                                                  "unsign",
                                                  "loanApplication",
                                                  "LoanApplicationForm"
                                                )
                                              }
                                              size="sm"
                                              id="download"
                                            >
                                              Loan Application Form
                                            </Dropdown.Item>
                                            {item.status === rejected && (
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleDownload(
                                                    item.loanApplicationId,
                                                    item.clientName,
                                                    item.loanApplicationNo,
                                                    "unsign",
                                                    "letter",
                                                    "RejectLetter"
                                                  )
                                                }
                                                id="rejectLetterDownload"
                                              >
                                                Reject Letter
                                              </Dropdown.Item>
                                            )}

                                            {item.status >=
                                              downloadLoanOffer && (
                                              <>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleDownload(
                                                      item.loanApplicationId,
                                                      item.clientName,
                                                      item.loanApplicationNo,
                                                      "unsign",
                                                      "letter",
                                                      "OfferLetter"
                                                    )
                                                  }
                                                  id="offerLetterDownload"
                                                >
                                                  Offer Letter
                                                </Dropdown.Item>
                                              </>
                                            )}
                                            {item.status >= scheduleDetails && (
                                              <Dropdown.Item
                                                id="scheduleDownload"
                                                onClick={() =>
                                                  handleScheduleModal(
                                                    item.loanApplicationId,
                                                    item.isSecured,
                                                    item.loanApplicationNo,
                                                    item.clientName
                                                  )
                                                }
                                              >
                                                Schedule
                                              </Dropdown.Item>
                                            )}

                                            {item.status >= downloadMemo && (
                                              <Dropdown.Item
                                                id="memoDownload"
                                                onClick={() =>
                                                  handleDownload(
                                                    item.loanApplicationId,
                                                    item.clientName,
                                                    item.loanApplicationNo,
                                                    "unsign",
                                                    "memo",
                                                    "memo"
                                                  )
                                                }
                                              >
                                                Memo
                                              </Dropdown.Item>
                                            )}

                                            {item.status >=
                                              downloadDisbursement && (
                                              <Dropdown.Item
                                                id="disbursementDownload"
                                                onClick={() =>
                                                  handleDownload(
                                                    item.loanApplicationId,
                                                    item.clientName,
                                                    item.loanApplicationNo,
                                                    "unsign",
                                                    "disbursement",
                                                    "disbursementLetter"
                                                  )
                                                }
                                              >
                                                Disbursement
                                              </Dropdown.Item>
                                            )}

                                            {item.status >
                                              signLoanApplication && (
                                              <>
                                                <DropdownDivider />
                                                {/* ------------------------------------------------- Signed ---------------------------------------------------------*/}

                                                <Dropdown.Header as="h6">
                                                  Download (signed) :
                                                </Dropdown.Header>
                                                <Dropdown.Item
                                                  id="signLoanApplicationDownload"
                                                  onClick={() => {
                                                    handleDownload(
                                                      item.loanApplicationId,
                                                      item.clientName,
                                                      item.loanApplicationNo,
                                                      "signed",
                                                      "loanApplication",
                                                      "Signed_loanApplication"
                                                    );
                                                  }}
                                                >
                                                  Loan Application Form
                                                </Dropdown.Item>
                                                {item.status >
                                                  signLoanOffer && (
                                                  <Dropdown.Item
                                                    id="signedOfferLetterDownload"
                                                    onClick={() => {
                                                      handleDownload(
                                                        item.loanApplicationId,
                                                        item.clientName,
                                                        item.loanApplicationNo,
                                                        "signed",
                                                        "loanOffer",
                                                        "Signed_OfferLetter"
                                                      );
                                                    }}
                                                  >
                                                    Offer Letter
                                                  </Dropdown.Item>
                                                )}
                                                {item.status >
                                                  uploadedBankReceipt && (
                                                  <Dropdown.Item
                                                    id="signedBankReceiptDownload"
                                                    onClick={() => {
                                                      handleDownload(
                                                        item.loanApplicationId,
                                                        item.clientName,
                                                        item.loanApplicationNo,
                                                        "basic",
                                                        "bankReceipt",
                                                        "BankReceipt"
                                                      );
                                                    }}
                                                  >
                                                    Bank Receipt
                                                  </Dropdown.Item>
                                                )}
                                                {item.status > signSchedule && (
                                                  <Dropdown.Item
                                                    id="signedScheduleDownload"
                                                    onClick={() => {
                                                      handleDownload(
                                                        item.loanApplicationId,
                                                        item.clientName,
                                                        item.loanApplicationNo,
                                                        "signed",
                                                        "schedule",
                                                        "Signed_Schedule"
                                                      );
                                                    }}
                                                  >
                                                    Schedule
                                                  </Dropdown.Item>
                                                )}
                                                {item.status > signMemo && (
                                                  <Dropdown.Item
                                                    id="signedMemoDownload"
                                                    onClick={() => {
                                                      handleDownload(
                                                        item.loanApplicationId,
                                                        item.clientName,
                                                        item.loanApplicationNo,
                                                        "signed",
                                                        "memo",
                                                        "Signed_Memo"
                                                      );
                                                    }}
                                                  >
                                                    Memo
                                                  </Dropdown.Item>
                                                )}
                                              </>
                                            )}
                                            {item.status > signDisbursement && (
                                              <Dropdown.Item
                                                id="signedDisbursementDownload"
                                                onClick={() => {
                                                  handleDownload(
                                                    item.loanApplicationId,
                                                    item.clientName,
                                                    item.loanApplicationNo,
                                                    "signed",
                                                    "disbursement",
                                                    "Signed_DisbursementLetter"
                                                  );
                                                }}
                                              >
                                                Disbursement
                                              </Dropdown.Item>
                                            )}

                                            {item.status >= disbursed && (
                                              <>
                                                <DropdownDivider />
                                                <Dropdown.Header>
                                                  Reminder Letter
                                                </Dropdown.Header>
                                                <Dropdown.Item
                                                  id="firstReminder"
                                                  onClick={() => {
                                                    handleFirstReminderModal(
                                                      item.loanApplicationId,
                                                      item.clientName,
                                                      item.loanApplicationNo
                                                    );
                                                  }}
                                                >
                                                  First Reminder
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  id="secondReminder"
                                                  onClick={() => {
                                                    handleSecondReminderModal(
                                                      item.loanApplicationId,
                                                      item.clientName,
                                                      item.loanApplicationNo
                                                    );
                                                  }}
                                                >
                                                  Second Reminder
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  id="warningLetter"
                                                  onClick={() => {
                                                    handleWarningLetterModal(
                                                      item.loanApplicationId,
                                                      item.clientName,
                                                      item.loanApplicationNo
                                                    );
                                                  }}
                                                >
                                                  Warning letter
                                                </Dropdown.Item>
                                              </>
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  {/* -------------------------Approve, reject , disburse button -------------------------*/}
                                  {item.status === approve_rejectButton ? (
                                    <>
                                      <Button
                                        variant="soft-danger"
                                        id="rejectBtn"
                                        size="sm"
                                        className="btn-rounded float-right m-2"
                                        onClick={() =>
                                          handleRejectModal(
                                            item.loanApplicationId
                                          )
                                        }
                                      >
                                        Reject
                                      </Button>
                                      <Button
                                        variant="soft-primary"
                                        id="approveBtn"
                                        size="sm"
                                        className="btn-rounded float-right m-2"
                                        onClick={() =>
                                          handleApproveModal(
                                            item.loanApplicationId,
                                            item.isSecured
                                          )
                                        }
                                      >
                                        Approve
                                      </Button>
                                    </>
                                  ) : item.status === disbursement ? (
                                    <>
                                      <Button
                                        variant="soft-primary"
                                        className="btn-rounded float-right m-2"
                                        size="sm"
                                        id="disbursementBtn"
                                        onClick={() =>
                                          handleDisburse(item.loanApplicationId)
                                        }
                                      >
                                        Disburse
                                      </Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Row>
                      <Col
                        md={{ span: 2, offset: 10 }}
                        className="text-center mt-3"
                      >
                        <Button
                          variant="primary btn-animated"
                          className="btn-icon"
                          disabled={currentPage === 1}
                          onClick={() => paginate(currentPage - 1)}
                        >
                          <span>
                            <i className="ri-arrow-left-s-line" />
                          </span>
                        </Button>
                        <span className="mx-1">{currentPage}</span>
                        <Button
                          variant="primary btn-animated "
                          className="btn-icon"
                          disabled={indexOfLastItem >= lists.length}
                          onClick={() => paginate(currentPage + 1)}
                        >
                          <span>
                            <i className="ri-arrow-right-s-line" />
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{ height: "200px" }}
                  >
                    <p>No loan application at the moment</p>
                  </div>
                )}
              </Card.Body>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center text-center"
                style={{ height: "200px" }}
              >
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {"\u00A0\u00A0\u00A0"}Fetching loan applications data...
                </>
              </div>
            )}
          </Card>
        </TabContent>
      </Container>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="success-container"
      />
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="downloadFail-container"
      />

      <AddFormModal
        showForm={showForm}
        handleCloseForm={handleCloseForm}
        accessToken={Token}
        fetchData={fetchData}
      />

      <LinkModalForm
        showForm={showLinkModalForm}
        handleCloseForm={handleCloseLinkModalForm}
        LinkGenerated={linkGeneration}
      />

      <EditFormModal
        showForm={showEditForm}
        handleCloseForm={handleCloseEditForm}
        accessToken={Token}
        fetchData={fetchData}
        loanApplicationId={loanApplicationId}
      />

      <DeleteFormModal
        showForm={showDeleteForm}
        handleCloseForm={closeHandleDelete}
        accessToken={Token}
        fetchData={fetchData}
        loanApplicationId={loanApplicationId}
        showDeleted={showDeleted}
      />

      <LoanApplicationModal
        showForm={showUploadForm}
        handleCloseForm={handleCloseUploadForm}
        loanApplicationId={loanApplicationId}
        accessToken={Token}
        fetchData={fetchData}
      />

      <ApproveModal
        showForm={showApproveForm}
        handleCloseForm={handleCloseApproveForm}
        accessToken={Token}
        isSecured={secured}
        loanApplicationId={loanApplicationId}
        fetchData={fetchData}
      />
      <OfferLetterSignedModal
        showForm={showOfferLetter}
        handleCloseForm={handleCloseOfferForm}
        loanApplicationId={loanApplicationId}
        accessToken={Token}
        fetchData={fetchData}
      />

      <RejectModal
        showForm={showRejectForm}
        handleCloseForm={handleCloseRejectForm}
        accessToken={Token}
        loanApplicationId={loanApplicationId}
        fetchData={fetchData}
      />

      <BankReceiptModal
        showForm={showBankReceiptForm}
        handleCloseForm={handleCloseBankReceiptForm}
        accessToken={Token}
        loanApplicationId={loanApplicationId}
        fetchData={fetchData}
      />
      <ScheduleModal
        showForm={showScheduleForm}
        handleCloseForm={handleCloseScheduleForm}
        loanApplicationId={loanApplicationId}
        accessToken={Token}
        isSecured={secured}
        loanApplicationNumber={loanApplicationNumber}
        clientName={clientName}
        fetchData={fetchData}
      />
      <ScheduleUploadModal
        showForm={showUploadScheduleForm}
        handleCloseForm={handleCloseScheduleUploadForm}
        isSecured={secured}
        loanApplicationId={loanApplicationId}
        accessToken={Token}
        fetchData={fetchData}
      />
      <DisbursementModal
        showForm={showDisbursementForm}
        handleCloseForm={handleCloseDisbursementModal}
        loanApplicationId={loanApplicationId}
        accessToken={Token}
        fetchData={fetchData}
      />
      <MemoModal
        showForm={showMemoForm}
        handleCloseForm={handleCloseMemoModal}
        loanApplicationId={loanApplicationId}
        accessToken={Token}
        fetchData={fetchData}
      />
      <FirstReminderModal
        showForm={showFirstReminderModal}
        handleCloseForm={handleCloseFirstReminderModal}
        loanApplicationId={loanApplicationId}
        accessToken={Token}
        loanApplicationNumber={loanApplicationNumber}
        clientName={clientName}
      />
      <SecondReminderModal
        showForm={showSecondReminderModal}
        handleCloseForm={handleCloseSecondReminderModal}
        loanApplicationId={loanApplicationId}
        accessToken={Token}
        loanApplicationNumber={loanApplicationNumber}
        clientName={clientName}
      />
      <WarningLetterModal
        showForm={showWarningLetter}
        handleCloseForm={handleCloseWarningLetterModal}
        loanApplicationId={loanApplicationId}
        accessToken={Token}
        loanApplicationNumber={loanApplicationNumber}
        clientName={clientName}
      />
    </>
  );
};

export default LoanApplicationView;
