import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import "react-toastify/dist/ReactToastify.css";

const AddFormModal = (props) => {
  const [clientName, setclientName] = useState("");
  const [ICNumber, setICNumber] = useState("");
  const [companyRegistrationNo, setcompanyRegistrationNo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loanApplicationNumber, setLoanApplicationNumber] = useState("");
  const [nationality, setNationality] = useState("");
  const [passportNumber, setPassportNumber] = useState("");

  //is submiting
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formMode, setFormMode] = useState("personal");
  const [isPersonal, setIsPersonal] = useState(true);
  //require("dotenv").config();

  // eslint-disable-next-line no-undef
  const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    await fetch(`${backendUri}/api/admin/create-application`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
      },
      body: JSON.stringify({
        clientName,
        ICNumber,
        companyName,
        companyRegistrationNo:
          isPersonal === true ? "None" : companyRegistrationNo,
        loanApplicationNumber,
        passportNumber,
        nationality,
        isPersonal,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          // Handle error, for example, display an error message to the user
          console.error("Error creating loan application1:", res.error);
          toast.error(res.error, {
            containerId: "error-container",
          });
        } else {
          toast.success("Loan application created successfully.", {
            containerId: "success-container",
          });

          // Reset form fields
          setclientName("");
          setICNumber("");
          setcompanyRegistrationNo("");
          setCompanyName("");
          setLoanApplicationNumber("");
          setNationality("");
          setPassportNumber("");
          setIsPersonal(true);

          // Set formMode based on isPersonal
          setFormMode(isPersonal ? "personal" : "corporate");
          props.fetchData(props.accessToken);
          props.handleCloseForm();
        }

        setIsSubmitting(false);
      })
      .catch((err) => {
        console.error("Fetch API fail");
        setIsSubmitting(false);
      });
    // setIsSubmitting(false);
  };
  const handleLoanTypeChange = (event) => {
    setIsPersonal(event.target.value === "personal");
    setFormMode(event.target.value === "personal" ? "personal" : "corporate");
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Loan Application Form</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row className="mb-2">
              <fieldset>
                <Form.Group controlId="formLoanType">
                  <Form.Label>Types of Loan : </Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        type="radio"
                        label="Personal Loan"
                        value="personal"
                        name="loanType"
                        id="personalLoanRadios"
                        checked={isPersonal}
                        onChange={handleLoanTypeChange}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="radio"
                        label="Corporate Loan"
                        value="corporate"
                        name="loanType"
                        id="corporateLoanRadios"
                        checked={!isPersonal}
                        onChange={handleLoanTypeChange}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </fieldset>
            </Row>
            <Row className="mb-2">
              <Form.Group controlId="formName">
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  value={clientName}
                  onChange={(e) => {
                    setclientName(e.target.value);
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group>
                <Form.Label>Nationality</Form.Label>
                <Form.Select
                  name="nationality"
                  value={nationality}
                  onChange={(e) => {
                    setNationality(e.target.value);
                  }}
                  required
                >
                  <option value="">Choose...</option>
                  <option value="Malaysian">Malaysian</option>
                  <option value="Non-Malaysian">Non-Malaysian</option>
                </Form.Select>
              </Form.Group>
            </Row>
            {nationality !== "" ? (
              <Row className="mb-2">
                <Form.Group controlId="formIC">
                  <Form.Label>
                    {nationality === "Malaysian"
                      ? "IC Number (without '-')"
                      : "Passport Number"}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      nationality === "Malaysian"
                        ? "Enter IC"
                        : "Enter Passport Number"
                    }
                    value={
                      nationality === "Malaysian" ? ICNumber : passportNumber
                    }
                    onChange={(e) => {
                      nationality === "Malaysian"
                        ? setICNumber(e.target.value)
                        : setPassportNumber(e.target.value);
                    }}
                    maxLength={nationality === "Malaysian" ? 12 : null}
                    minLength={nationality === "Malaysian" ? 12 : null}
                  />
                </Form.Group>
              </Row>
            ) : (
              ""
            )}

            {!isPersonal && (
              <>
                <Row className="mb-3">
                  <Form.Group controlId="formCompanyName">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter ComapnyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="formCompanyNo">
                    <Form.Label>Company Registration No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Registration Number"
                      value={companyRegistrationNo}
                      onChange={(e) => setcompanyRegistrationNo(e.target.value)}
                    />
                  </Form.Group>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              type="submit"
              id="addSubmitBtn"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  {/* <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span> */}
                  <span>Submitting...</span>
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Button variant="soft-secondary" onClick={props.handleCloseForm}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddFormModal;
