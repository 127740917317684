import React, { useState, useEffect } from "react";
import { Col, Form, Row, InputGroup } from "react-bootstrap";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const SectionD = ({
  handleFileInputChange,
  loanDetails,
  ID,
  handleInputChange,
  isinvalid,
}) => {
  const [applicantType, setApplicantType] = useState(""); // Default to salaried
  const acceptedFileTypes = ".pdf, .txt, .png, .jpg, .jpeg, .gif, .docx";

  const handleApplicantTypeChange = (event) => {
    setApplicantType(event.target.value);
    handleInputChange(event);
  };

  const UploadedFiles = ({ fileKey, files }) => {
    return (
      <Form.Text className="text-muted">
        {Array.isArray(files) && files.length > 0 ? (
          <>
            {files.length > 1 ? "Uploaded Files: " : "Uploaded File: "}
            {files.map((fileName, index) => (
              <React.Fragment key={index}>
                {index > 0 && ", "}
                <a
                  href={`${backendUri}/api/client/download-file/${ID}/${fileKey}?filename=${fileName}`}
                  download={fileName}
                >
                  {fileName}
                </a>
              </React.Fragment>
            ))}
          </>
        ) : (
          "No file(s) uploaded"
        )}
      </Form.Text>
    );
  };

  return (
    <Form.Group controlId="formStep3">
      <h1 id="titleSD" className="pg-title mb-4">
        Important Documents
      </h1>
      <Row className="mb-3">
        <h5>All Applicants</h5>
        <Form.Group
          as={Col}
          controlId="photocopyOfIC"
          sm={12}
          lg={4}
          className="mb-3 mb-lg-0"
        >
          <Form.Label>Photocopy of IC (both sides)*</Form.Label>
          <InputGroup>
            <Form.Control
              type="file"
              name="photocopyOfIC"
              accept={acceptedFileTypes}
              onChange={handleFileInputChange}
              isInvalid={isinvalid && !loanDetails.photocopyOfIC}
            />
          </InputGroup>
          <UploadedFiles
            fileKey="photocopyOfIC"
            files={[loanDetails.photocopyOfIC]}
          />
          <Form.Control.Feedback type="invalid">
            Please provide document.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="epfStatement"
          sm={12}
          lg={4}
          className="mb-3 mb-lg-0"
        >
          <Form.Label>EPF Statements*</Form.Label>
          <InputGroup>
            <Form.Control
              type="file"
              name="epfStatement"
              accept={acceptedFileTypes}
              onChange={handleFileInputChange}
              isInvalid={isinvalid && !loanDetails.epfStatement}
            />
          </InputGroup>
          <UploadedFiles
            fileKey="epfStatement"
            files={[loanDetails.epfStatement]}
          />
          <Form.Control.Feedback type="invalid">
            Please provide EPF Statement.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="allApplicantBankStatements"
          sm={12}
          lg={4}
          className="mb-lg-0"
        >
          <Form.Label>Latest 3 Months Bank Statements*</Form.Label>
          <InputGroup>
            <Form.Control
              type="file"
              name="allApplicantBankStatements"
              accept={acceptedFileTypes}
              onChange={handleFileInputChange}
              isInvalid={isinvalid && !loanDetails.allApplicantBankStatements}
            />
          </InputGroup>
          <UploadedFiles
            fileKey="allApplicantBankStatements"
            files={[loanDetails.allApplicantBankStatements]}
          />
          <Form.Control.Feedback type="invalid">
            Please provide document.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group as={Col} controlId="others" className="mb-3">
        <Form.Label>Others</Form.Label>
        <InputGroup>
          <Form.Control
            type="file"
            multiple
            name="others"
            accept={acceptedFileTypes}
            onChange={handleFileInputChange}
          />
        </InputGroup>
        <UploadedFiles fileKey="others" files={loanDetails.others} />
      </Form.Group>
      <Form.Group controlId="applicantsType" className="mb-3">
        <Form.Label>Applicants Type*</Form.Label>
        <Form.Select
          name="applicantType"
          value={loanDetails.applicantType}
          onChange={handleApplicantTypeChange}
          isInvalid={isinvalid && !loanDetails.applicantType}
        >
          <option value="">Choose...</option>
          <option value="Salaried Applicants">Salaried Applicants</option>
          <option value="Self-Employed Applicants">
            Self-Employed Applicants
          </option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Please select an applicatn type.
        </Form.Control.Feedback>
      </Form.Group>
      {loanDetails.applicantType === "Salaried Applicants" ? (
        <Row className="mb-3">
          <h5>Salaried Applicants</h5>
          <Form.Group
            as={Col}
            controlId="salaryConfirmationLetter"
            sm={12}
            md={6}
            className="mb-3 mb-md-0"
          >
            <Form.Label>
              Employer's Confirmation Letter on Current Salary*
            </Form.Label>
            <InputGroup>
              <Form.Control
                type="file"
                name="salaryConfirmationLetter"
                accept={acceptedFileTypes}
                onChange={handleFileInputChange}
                isInvalid={isinvalid && !loanDetails.salaryConfirmationLetter}
              />
            </InputGroup>
            <UploadedFiles
              fileKey="salaryConfirmationLetter"
              files={[loanDetails.salaryConfirmationLetter]}
            />
            <Form.Control.Feedback type="invalid">
              Please provide document.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="salarySlip"
            sm={12}
            md={6}
            className="mb-3 mb-md-0"
          >
            <Form.Label>Latest 3 Months' Salary Slip*</Form.Label>
            <InputGroup>
              <Form.Control
                type="file"
                name="salarySlip"
                accept={acceptedFileTypes}
                onChange={handleFileInputChange}
                isInvalid={isinvalid && !loanDetails.salarySlip}
              />
            </InputGroup>
            <UploadedFiles
              fileKey="salarySlip"
              files={[loanDetails.salarySlip]}
            />
            <Form.Control.Feedback type="invalid">
              Please provide document.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      ) : loanDetails.applicantType === "Self-Employed Applicants" ? (
        <Row className="mb-3">
          <h5>Self-Employed Applicants</h5>
          <Form.Group as={Col} controlId="ssm" className="mb-3">
            <Form.Label>
              Business Registration Form (SSM) Business Corporate Info*
            </Form.Label>
            <InputGroup>
              <Form.Control
                type="file"
                name="ssm"
                accept={acceptedFileTypes}
                onChange={handleFileInputChange}
                isInvalid={isinvalid && !loanDetails.ssm}
              />
            </InputGroup>
            <UploadedFiles fileKey="ssm" files={[loanDetails.ssm]} />
            <Form.Control.Feedback type="invalid">
              Please provide document.
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="profitAndLossStatement"
              sm={12}
              md={6}
              className="mb-3 mb-md-0"
            >
              <Form.Label>Profit and Loss Statement*</Form.Label>
              <InputGroup>
                <Form.Control
                  type="file"
                  name="profitAndLossStatement"
                  accept={acceptedFileTypes}
                  onChange={handleFileInputChange}
                  isInvalid={isinvalid && !loanDetails.profitAndLossStatement}
                />
              </InputGroup>
              <UploadedFiles
                fileKey="profitAndLossStatement"
                files={[loanDetails.profitAndLossStatement]}
              />
              <Form.Control.Feedback type="invalid">
                Please provide document.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="selfEmployedBankStatement"
              sm={12}
              md={6}
              className="mb-md-0"
            >
              <Form.Label>3 Months Banks Statement*</Form.Label>
              <InputGroup>
                <Form.Control
                  type="file"
                  name="selfEmployedBankStatement"
                  accept={acceptedFileTypes}
                  onChange={handleFileInputChange}
                  isInvalid={isinvalid && !loanDetails.selfEmployedBankStatement}
                />
              </InputGroup>
              <UploadedFiles
                fileKey="selfEmployedBankStatement"
                files={[loanDetails.selfEmployedBankStatement]}
              />
              <Form.Control.Feedback type="invalid">
                Please provide document.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Row>
      ) : null}
    </Form.Group>
  );
};

export default SectionD;
