import React, { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const AddClientModal = (props) => {
  const [clientName, setClientName] = useState("");
  const [ICNumber, setICNumber] = useState("");
  const [mobileNumber, setcMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [nationality, setNationality] = useState("");
  const [passportNumber, setPassportNumber] = useState("");

  //is submiting
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    await fetch(`${backendUri}/api/admin/clients`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
      },
      body: JSON.stringify({
        clientName,
        ICNumber,
        mobileNumber,
        email,
        nationality,
        passportNumber,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });

          // Reset form fields
          setClientName("");
          setICNumber("");
          setcMobileNo("");
          setEmail("");
          setNationality("");

          props.fetchData(props.accessToken);
          props.handleCloseForm();
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        console.error("Fetch API  fail");
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title> Create New Client </Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row className="mb-2">
              <Form.Group controlId="addName">
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  value={clientName}
                  onChange={(e) => {
                    setClientName(e.target.value);
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group controlId="addNationality">
                <Form.Label>Nationality</Form.Label>
                <Form.Select
                  id="addNationality"
                  name="nationality"
                  value={nationality}
                  onChange={(e) => {
                    setNationality(e.target.value);
                  }}
                >
                  <option value="">Choose...</option>
                  <option value="Malaysian">Malaysian</option>
                  <option value="Non-Malaysian">Non-Malaysian</option>
                </Form.Select>
              </Form.Group>
            </Row>
            {nationality !== "" ? (
              <Row className="mb-2">
                <Form.Group controlId="formIC">
                  <Form.Label>
                    {nationality === "Malaysian"
                      ? "IC Number (without '-')"
                      : "Passport Number"}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      nationality === "Malaysian"
                        ? "Enter IC"
                        : "Enter Passport Number"
                    }
                    value={
                      nationality === "Malaysian" ? ICNumber : passportNumber
                    }
                    onChange={(e) => {
                      nationality === "Malaysian"
                        ? setICNumber(e.target.value)
                        : setPassportNumber(e.target.value);
                    }}
                    maxLength={nationality === 'Malaysian' ? 12 : null}
                    minLength={nationality === 'Malaysian' ? 12 : null}
                  />
                </Form.Group>
              </Row>
            ) : (
              ""
            )}
            <Row className="mb-2">
              <Form.Group controlId="addPhoneNumber">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => {
                    setcMobileNo(e.target.value);
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group controlId="addEmail">
                <Form.Label>Client Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              type="submit"
              id="addSubmitBtn"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span>Submitting...</span>
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.handleCloseForm}
              id="closeCreateFormBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
        <ToastContainer
          position="top-right"
          style={{ top: "70px", right: "20px" }}
          enableMultiContainer
          containerId="error-container"
        />
      </Modal>
    </>
  );
};

export default AddClientModal;
