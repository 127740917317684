import React, { useState, useEffect, useRef } from "react";
import { Country, State } from "country-state-city";
import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

const SectionB = ({
  handleInputChange,
  clientDetails,
  mobilePhoneMask,
  homeNumberMask,
  isinvalid,
}) => {
  const [clientDOB, setClientDOB] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [race, setRace] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const myRef = useRef();

  // useEffect(() => {
  //   const dateString = clientDetails.dateOfBirth;
  //   if (dateString) {
  //     const momentDate = moment(dateString);
  //     const formattedDateString = momentDate.format("DD/MM/YYYY");
  //     setClientDOB(formattedDateString);
  //     myRef.current.setStartDate(formattedDateString);
  //     myRef.current.setEndDate(formattedDateString);
  //   }
  // }, [clientDetails.dateOfBirth]);

  useEffect(() => {
    setSelectedCountry(clientDetails.country);
  }, [clientDetails.country]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
    if (!clientDetails.country) {
      const malaysia = allCountries.find(
        (country) => country.name === "Malaysia"
      );
      if (malaysia) {
        setSelectedCountry(malaysia.isoCode);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const statesForCountry = State.getStatesOfCountry(selectedCountry);
      setStates(statesForCountry);
      setSelectedState("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (clientDetails.race === undefined) {
      setRace("");
    } else if (
      !["Malay", "Chinese", "Indian", ""].includes(clientDetails.race)
    ) {
      setRace("Others");
    } else {
      setRace(clientDetails.race);
    }
  }, [clientDetails.race]);

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setSelectedCountry(newCountry);
    handleInputChange(event);
  };

  const handleStateChange = (event) => {
    const newState = event.target.value;
    setSelectedState(newState);
    handleInputChange(event);
  };

  const handleDateChange = (selectedDate) => {
    // setClientDOB(selectedDate.format("DD/MM/YYYY"));
    // const formattedDate = selectedDate.toISOString();
    // handleInputChange({
    //   target: {
    //     name: "dateOfBirth",
    //     value: formattedDate,
    //   },
    // });
    handleInputChange({
      target: {
        name: "dateOfBirth",
        value: selectedDate,
      },
    });
  };

  return (
    <Form.Group controlId="formStep2">
      <h1 id="titleSB" className="pg-title mb-4">
        Your Personal Details
      </h1>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="clientName"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Full Name*</Form.Label>
          <Form.Control
            readOnly
            type="text"
            placeholder="Full Name"
            name="clientName"
            value={clientDetails.clientName || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientEmail"
          sm={12}
          md={6}
          className="mb-md-0"
        >
          <Form.Label>Email*</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            value={clientDetails.email || ""}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.email}
          />
          <Form.Control.Feedback type="invalid">
            Please provide an email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="clientGender"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Gender*</Form.Label>
          <Form.Select
            name="gender"
            value={clientDetails.gender}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.gender}
          >
            <option value="">Choose...</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Not to mention">Not to mention</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a gender.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientNationality"
          sm={12}
          md={6}
          className="mb-md-0"
        >
          <Form.Label>Nationality*</Form.Label>
          <Form.Control
            readOnly
            name="nationality"
            value={clientDetails.nationality}
            onChange={handleInputChange}
          ></Form.Control>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        {/* <Form.Group as={Col} controlId="clientDOB" sm={12} md={3} className="mb-3 mb-md-0">
          <Form.Label>Date of Birth*</Form.Label>
          <DateRangePicker ref={myRef}
            initialSettings={{
              singleDatePicker: true,
              showDropdowns: true,
              autoApply: true,
              minYear: 1901,
              maxYear: parseInt(moment().format("YYYY"), 10),
              locale: {
                format: "DD/MM/YYYY",
              },
              startDate: clientDOB || "01/01/1901"
            }}
            onApply={(event, picker) => handleDateChange(picker.startDate)}
          >
            <Form.Control
              type="text"
              name="dateOfBirth"
              value={clientDOB}
              onChange={() => {}}
            />
          </DateRangePicker>
          
        </Form.Group> */}

        {clientDetails.nationality === "Malaysian" ? (
          <Form.Group
            as={Col}
            controlId="clientICNumber"
            sm={12}
            md={4}
            className="mb-3 mb-md-0"
          >
            <Form.Label>NRIC No. (New)*</Form.Label>
            <InputMask
              readOnly
              className="form-control"
              mask="999999-99-9999"
              name="ICNumber"
              defaultValue={clientDetails.ICNumber || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        ) : (
          <Form.Group
            as={Col}
            controlId="clientICNumber"
            sm={12}
            md={4}
            className="mb-3 mb-md-0"
          >
            <Form.Label>Passport No.*</Form.Label>
            <InputMask
              readOnly
              className="form-control"
              name="passportNumber"
              defaultValue={clientDetails.passportNumber || ""}
            />
          </Form.Group>
        )}

        <Form.Group
          as={Col}
          controlId="clientDOB"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Date of Birth*</Form.Label>
          <Form.Control
            type="date"
            value={clientDetails.dateOfBirth || ""}
            onChange={(e) => {
              handleDateChange(e.target.value);
            }}
            isInvalid={isinvalid && !clientDetails.dateOfBirth}
          />
          <Form.Control.Feedback type="invalid">
            Please select a date.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientRace"
          sm={12}
          md={3}
          className="mb-md-0"
        >
          <Form.Label>Race*</Form.Label>
          <Form.Select
            name="race"
            value={race}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.race}
          >
            <option value="">Choose...</option>
            <option value="Malay">Malay</option>
            <option value="Chinese">Chinese</option>
            <option value="Indian">Indian</option>
            <option value="Others">Others</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a race.
          </Form.Control.Feedback>
        </Form.Group>
        {race === "Others" && (
          <Form.Group as={Col} controlId="otherRace" className="mt-3 mt-md-0">
            <Form.Label>Specify Other Race</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter other race"
              name="otherRace"
              defaultValue={clientDetails.race || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        )}
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="clientMaritalStatus"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Marital Status*</Form.Label>
          <Form.Select
            name="maritalStatus"
            value={clientDetails.maritalStatus}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.maritalStatus}
          >
            <option value="">Choose...</option>
            <option value="Single">Single</option>
            <option value="Married">Married</option>
            <option value="Widowed">Widowed</option>
            <option value="Divorced">Divorced</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a marital status.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientDependentsNumber"
          sm={12}
          md={6}
          className="mb-md-0"
        >
          <Form.Label>No. of Dependents*</Form.Label>
          {/* <Form.Control
            name="dependentsNumber"
            defaultValue={
              clientDetails.dependentsNumber !== undefined &&
              clientDetails.dependentsNumber !== null
                ? clientDetails.dependentsNumber
                : ""
            }
            onChange={handleInputChange}
          /> */}
          <NumericFormat
            name="dependentsNumber"
            value={
              clientDetails.dependentsNumber !== undefined &&
              clientDetails.dependentsNumber !== null
                ? clientDetails.dependentsNumber
                : ""
            }
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange({
                target: {
                  name: "dependentsNumber",
                  value: value,
                },
              });
            }}
            customInput={Form.Control}
            allowNegative={false}
            isInvalid={
              isinvalid &&
              (clientDetails.dependentsNumber === null ||
                clientDetails.dependentsNumber === "" || clientDetails.dependentsNumber === undefined)
            }
          />
          <Form.Control.Feedback type="invalid">
            Please provide a value.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="clientMobileNo"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Mobile No.*</Form.Label>
          <InputMask
            className={`form-control ${
              isinvalid &&
              (!clientDetails.mobileNumber ||
                clientDetails.mobileNumber.includes("_"))
                ? "is-invalid"
                : ""
            }`}
            name="mobileNumber"
            mask={mobilePhoneMask}
            value={clientDetails.mobileNumber || ""}
            onChange={handleInputChange}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid phone number.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientHomeNo"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Home No.</Form.Label>
          <InputMask
            className={`form-control ${
              clientDetails.homeNumber && clientDetails.homeNumber.includes("_")
                ? "is-invalid"
                : ""
            }`}
            name="homeNumber"
            mask={homeNumberMask}
            value={clientDetails.homeNumber || ""}
            onChange={handleInputChange}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid home number.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <h5>Home Address</h5>
      <Form.Group className="mb-3" controlId="clientAddressLine1">
        <Form.Label>Address Line 1*</Form.Label>
        <Form.Control
          placeholder="1234 Main St"
          name="addressLine1"
          value={clientDetails.addressLine1 || ""}
          onChange={handleInputChange}
          isInvalid={isinvalid && !clientDetails.addressLine1}
        />
        <Form.Control.Feedback type="invalid">
          Please provide an address.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="clientAddressLine2">
        <Form.Label>Address Line 2</Form.Label>
        <Form.Control
          placeholder="Apartment, studio, or floor"
          name="addressLine2"
          defaultValue={clientDetails.addressLine2 || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="clientCity"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Area*</Form.Label>
          <Form.Control
            name="city"
            onChange={handleInputChange}
            defaultValue={clientDetails.city}
            isInvalid={isinvalid && !clientDetails.city}
          />
          <Form.Control.Feedback type="invalid">
            Please provide an area.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientZipCode"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Postal Code*</Form.Label>
          <NumericFormat
            name="zipCode"
            value={clientDetails.zipCode || ""}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange({
                target: {
                  name: "zipCode",
                  value: value,
                },
              });
            }}
            customInput={Form.Control}
            allowNegative={false}
            decimalScale={0}
            isInvalid={isinvalid && !clientDetails.zipCode}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a postal code.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientState"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>State*</Form.Label>
          <Form.Select
            name="state"
            onChange={handleStateChange}
            value={clientDetails.state}
            isInvalid={isinvalid && !clientDetails.state}
          >
            <option value="">Choose...</option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a state.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientCountry"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Country*</Form.Label>
          <Form.Select
            name="country"
            onChange={handleCountryChange}
            value={clientDetails.country || selectedCountry}
            disabled
          >
            <option value="">Choose...</option>
            {countries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
    </Form.Group>
  );
};

export default SectionB;
