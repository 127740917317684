import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Container, Card } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLoanApplicationService from "../services/loanApplicationService";
import Cookies from "js-cookie";
import vestiuumImgLogo from "../../assets/dist/img/vestiuum1.png";
import vestiuumImg from "../../assets/dist/img/vestiuum.png";
import { NumericFormat } from "react-number-format";

const CreateLoanApplication = () => {
  const [clientName, setclientName] = useState("");
  const [ICNumber, setICNumber] = useState("");
  const [companyRegistrationNo, setcompanyRegistrationNo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loanApplicationNumber, setLoanApplicationNumber] = useState("");
  const [nationality, setNationality] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [formLink, setFormLink] = useState("");
  const {
    generateLoanApplicationLink,
    clientGenerateLoanApplicationLink,
    loanApplicationStatusMapping,
    loanApplicationStatus,
  } = useLoanApplicationService();
  const currentYear = new Date().getFullYear();

  //is submiting
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formMode, setFormMode] = useState("personal");
  const [isPersonal, setIsPersonal] = useState(true);
  //require("dotenv").config();

  // eslint-disable-next-line no-undef
  const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
  const frontendUri = process.env.REACT_APP_FRONTEND_DOMAIN;
  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    await fetch(`${backendUri}/api/client/create-application`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clientName,
        ICNumber,
        companyName,
        companyRegistrationNo:
          isPersonal === true ? "None" : companyRegistrationNo,
        loanApplicationNumber,
        passportNumber,
        nationality,
        isPersonal,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (res) => {
        if (res.error) {
          // Handle error, for example, display an error message to the userd
          let errorMessage;

          // if(loanApplicationStatusMapping[res.loanApplication.status] <= loanApplicationStatus.PENDING_DOWNLOAD_LOAN_APPLICATION) {
          //   console.log("1")
          //   errorMessage = "A previous loan application not yet completed or signed. Redirecting to the previous loan application..."
          // }else if(loanApplicationStatusMapping[res.loanApplication.status] > loanApplicationStatus.PENDING_DOWNLOAD_LOAN_APPLICATION && loanApplicationStatusMapping[res.loanApplication.status] < loanApplicationStatus.DISBURSED){
          //   console.log("2")
          //   errorMessage = "Previous loan application not yet completed. Unable to create new loan application."
          // }else{
          //   console.log("3")
          // }

          if (res.error === "Unfinished Loan Application") {
            if (
              loanApplicationStatusMapping[res.loanApplication.status] <=
              loanApplicationStatus.PENDING_DOWNLOAD_LOAN_APPLICATION
            ) {
              errorMessage =
                "A previous loan application not yet completed or signed. Redirecting to the previous loan application...";
            } else if (
              loanApplicationStatusMapping[res.loanApplication.status] >
                loanApplicationStatus.PENDING_DOWNLOAD_LOAN_APPLICATION &&
              loanApplicationStatusMapping[res.loanApplication.status] <
                loanApplicationStatus.DISBURSED
            ) {
              errorMessage =
                "Previous loan application not yet completed. Unable to create new loan application.";
            }
          } else {
            errorMessage = res.error;
          }

          toast.error(errorMessage, {
            containerId: "toast-container",
          });

          if (
            loanApplicationStatusMapping[res.loanApplication.status] <=
            loanApplicationStatus.PENDING_DOWNLOAD_LOAN_APPLICATION
          ) {
            const linkGenerated = await clientGenerateLoanApplicationLink(
              res.loanApplication._id
            );
            Cookies.set("clientLoanApplicationId", res.loanApplication._id, {
              expires: 10,
            }); // Cookie expires in 10 days
            setFormLink(linkGenerated.loanApplicationLink);
          }
        } else {
          Cookies.set("clientLoanApplicationId", res.loanApplicationId, {
            expires: 10,
          }); // Cookie expires in 10 days
          toast.success("Loan application created successfully.", {
            containerId: "toast-container",
          });

          // Reset form fields
          setclientName("");
          setICNumber("");
          setcompanyRegistrationNo("");
          setCompanyName("");
          setLoanApplicationNumber("");
          setNationality("");
          setPassportNumber("");
          setIsPersonal(true);

          // Set formMode based on isPersonal
          setFormMode(isPersonal ? "personal" : "corporate");
          setFormLink(res.loanApplicationLink);
        }

        setIsSubmitting(false);
      })
      .catch((err) => {
        console.error("Fetch API fail");
        setIsSubmitting(false);
      });
    // setIsSubmitting(false);
  };
  const handleLoanTypeChange = (event) => {
    setIsPersonal(event.target.value === "personal");
    setFormMode(event.target.value === "personal" ? "personal" : "corporate");
  };

  const loanApplicationLinkGeneration = async () => {
    const loanApplicationLink = await clientGenerateLoanApplicationLink(
      Cookies.get("clientLoanApplicationId")
    );

    return loanApplicationLink;
  };

  useEffect(() => {
    setFormLink(null);
    const fetchData = async () => {
      // Call async function and await for result
      const result = await loanApplicationLinkGeneration();

      // Assuming `result` is an object with `loanApplicationLink` property
      if (result && result.loanApplicationLink) {
        setFormLink(result.loanApplicationLink);
        toast.success("Redirecting to previous created loan application.....", {
          containerId: "toast-container",
        });
      } else {
        setFormLink(null);
        Cookies.remove("clientLoanApplicationId");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formLink) {
      const timer = setTimeout(() => {
        window.location.href = `${frontendUri}/clients/application_form/${formLink}`;
      }, 1500);
      // setFormLink(null)

      // Clean up the timeout if the component unmounts or if formLink changes
      return () => clearTimeout(timer);
    }
  }, [formLink]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <header>
        {/* <Container>
          <div className="p-3">
            © 2024 Your Company:
            <a className="text-dark" href="https://yourcompany.com/">
              {" "}
              yourcompany.com
            </a>
            Vestiuum Malaysia Sdn Bhd.
          </div>
        </Container> */}
        {/* <Container>
          <div className="p-3">
            <nav class="navbar navbar-light bg-light">
              <div class="container">
                <a class="navbar-brand" href="#">
                  <img
                    src={vestiuumImgLogo}
                    alt="logo"
                    style={{
                      width: "80px",
                      height: "80px",
                      marginLeft: "10px",
                    }}
                  />
                  <img
                    src={vestiuumImg}
                    alt="logo"
                    style={{
                      width: "150px",
                      height: "80px",
                      marginLeft: "10px",
                    }}
                  />
                </a>
              </div>
            </nav>
          </div>
        </Container> */}
        <Container fluid>
          <nav class="navbar navbar-light bg-light">
            <div class="container">
              <a class="navbar-brand" href="javascript:void(0);">
                <img
                  src={vestiuumImgLogo}
                  alt="logo"
                  style={{ width: "70px", height: "70px", marginLeft: "10px" }}
                />
                <img
                  src={vestiuumImg}
                  alt="logo"
                  style={{ height: "70px", marginLeft: "10px" }}
                />
              </a>
            </div>
          </nav>
        </Container>
      </header>
      <main className="flex-grow-1">
        <Container>
          <Card className="card-flush mt-5">
            <Card.Body>
              <h4 className="text-center">Apply new loan application</h4>
              <Row>
                <Col sm={10} className="position-relative mx-auto">
                  <div className="auth-content py-8">
                    <Form onSubmit={(e) => handleSubmit(e)}>
                      <fieldset disabled={formLink}>
                        <Form.Group controlId="formLoanType">
                          <Form.Label>Types of Loan: </Form.Label>
                          <Row>
                            <Col>
                              <Form.Check
                                type="radio"
                                label="Personal Loan"
                                value="personal"
                                name="loanType"
                                id="personalLoanRadios"
                                checked={isPersonal}
                                onChange={handleLoanTypeChange}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                type="radio"
                                label="Corporate Loan"
                                value="corporate"
                                name="loanType"
                                id="corporateLoanRadios"
                                checked={!isPersonal}
                                onChange={handleLoanTypeChange}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </fieldset>

                      <Row className="mb-2">
                        <Form.Group controlId="formName">
                          <Form.Label>Client Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            value={clientName}
                            onChange={(e) => setclientName(e.target.value)}
                          />
                        </Form.Group>
                      </Row>

                      <Row className="mb-2">
                        <Form.Group>
                          <Form.Label>Nationality</Form.Label>
                          <Form.Select
                            name="nationality"
                            value={nationality}
                            onChange={(e) => setNationality(e.target.value)}
                            required
                          >
                            <option value="">Choose...</option>
                            <option value="Malaysian">Malaysian</option>
                            <option value="Non-Malaysian">Non-Malaysian</option>
                          </Form.Select>
                        </Form.Group>
                      </Row>

                      {nationality && (
                        <Row className="mb-2">
                          <Form.Group controlId="formIC">
                            <Form.Label>
                              {nationality === "Malaysian"
                                ? "IC Number"
                                : "Passport Number"}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={
                                nationality === "Malaysian"
                                  ? "Enter IC"
                                  : "Enter Passport Number"
                              }
                              value={
                                nationality === "Malaysian"
                                  ? ICNumber
                                  : passportNumber
                              }
                              onChange={(e) =>
                                nationality === "Malaysian"
                                  ? setICNumber(e.target.value)
                                  : setPassportNumber(e.target.value)
                              }
                              maxLength={nationality === "Malaysian" ? 12 : null}
                              minLength={nationality === "Malaysian" ? 12 : null}
                            />
                          </Form.Group>
                        </Row>
                      )}

                      {!isPersonal && (
                        <>
                          <Row className="mb-3">
                            <Form.Group controlId="formCompanyName">
                              <Form.Label>Company Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Company Name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                              />
                            </Form.Group>
                          </Row>
                          <Row className="mb-3">
                            <Form.Group controlId="formCompanyNo">
                              <Form.Label>Company Registration No</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Registration Number"
                                value={companyRegistrationNo}
                                onChange={(e) =>
                                  setcompanyRegistrationNo(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Row>
                        </>
                      )}

                      <Button
                        variant="soft-primary"
                        type="submit"
                        id="addSubmitBtn"
                        disabled={isSubmitting || formLink}
                      >
                        {isSubmitting ? (
                          <>
                            <span>Submitting...</span>
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <ToastContainer
          position="top-right"
          style={{ top: "70px", right: "20px" }}
          enableMultiContainer
          containerId="toast-container"
        />
      </main>
      <footer className="bg-light text-center text-lg-start py-3 mt-auto">
        <Container>
          <div className="text-center p-3">
            Copyright Vestiuum © {currentYear} All Rights Reserved.
            {/* <a className="text-dark" href="https://vestiuum.com/">
              {" "}
              Vestiuum Malaysia
            </a>
            <br />
            Contact: +123-456-7890 | Email: info@vestiuum.com */}
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default CreateLoanApplication;
