import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

const SectionE = ({ handleInputChange, loanDetails, isinvalid }) => {
  const [bankName, setBankName] = useState("");
  const creditToOptions = [
    "Maybank",
    "OCBC Bank",
    "Affin Bank",
    "CIMB Bank",
    "RHB Bank",
    "Bank Muamalat",
    "Hong Leong Bank",
    "Bank Islam",
    "UOB Bank",
    "HSBC Bank",
    "Public Bank",
    "Alliance Bank",
    "Others",
  ];

  useEffect(() => {
    if (loanDetails.bankName === undefined) {
      setBankName("");
    } else if (
      !creditToOptions.includes(loanDetails.bankName) &&
      loanDetails.bankName !== ""
    ) {
      setBankName("Others");
    } else {
      setBankName(loanDetails.bankName);
    }
  }, [loanDetails.bankName]);

  return (
    <Form.Group controlId="formStep3">
      <h1 id="titleSE" className="pg-title mb-4">
        Disbursement Details
      </h1>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="bankName">
          <Form.Label>Credit to*</Form.Label>
          <Form.Select
            name="bankName"
            value={bankName}
            onChange={handleInputChange}
            isInvalid={isinvalid && !loanDetails.bankName}
          >
            <option value="">Choose...</option>
            {creditToOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a bank.
          </Form.Control.Feedback>
        </Form.Group>
        {bankName === "Others" && (
          <Form.Group
            as={Col}
            controlId="otherBankName"
            sm={12}
            md={6}
            className="mt-3 mt-md-0"
          >
            <Form.Label>Specify Other Bank*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter other bank name"
              name="otherBankName"
              defaultValue={loanDetails.bankName || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        )}
      </Row>
      <Form.Group as={Col} controlId="accountType" className="mb-3">
        <Form.Label>Account Type*</Form.Label>
        <Form.Select
          name="accountType"
          value={loanDetails.accountType}
          onChange={handleInputChange}
          isInvalid={isinvalid && !loanDetails.accountType}
        >
          <option value="">Choose...</option>
          <option value="Savings Account">Savings Account</option>
          <option value="Current Account">Current Account</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Please select an account type.
        </Form.Control.Feedback>
      </Form.Group>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="accountHolderName"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Account Holder Name*</Form.Label>
          <Form.Control
            name="accountHolderName"
            onChange={handleInputChange}
            value={loanDetails.accountHolderName || ""}
            isInvalid={isinvalid && !loanDetails.accountHolderName}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="accountNumber"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Account No.*</Form.Label>
          <NumericFormat
            aria-label="Account Number"
            name="accountNumber"
            value={loanDetails.accountNumber || ""}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange({
                target: {
                  name: "accountNumber",
                  value: value,
                },
              });
            }}
            customInput={Form.Control}
            allowNegative={false}
            isInvalid={isinvalid && !loanDetails.accountNumber}
          />
          <Form.Control.Feedback type="invalid">
            Please provide an account number.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </Form.Group>
  );
};

export default SectionE;
